<!-- 首页 -->
<template>
  <div class="home">

    <div class="cardType">
      <p>
        <span class="type">卡类:</span>

      <p style="margin:0px;padding:0px">
        <span v-for="(item, index) in cardTypeList" :key="index" :class="{
          cardTable: true,
          selectTable: cardTypeObj.gc_type == item.value,
        }" @click="changeCardType(item.value)">{{ item.text }}</span>
      </p>
      </p>

    </div>
    <div class="cardType">
      <p> <span class="type">卡种:</span></p>
      <p>
        <span v-for="(item, index) in cardNameList" :key="index" :class="{
          cardTable: true,
          selectTable: reqMoneyCardListObj.gc_id == item.gc_id,
        }" @click="changeCard(item)">{{ item.gc_name }}</span>
        <span v-if="cardNameList.length < 1">暂无,请看看其他类型哦...</span>

      </p>
      <img v-if="cardNameList.length >= 1" :src="logImg2" alt="log" class="typeLogo" />
    </div>
    <div class="selectMoney">
      <selectMoney :cardList="cardList" :cardTypeObj='toSubmitCompontetObj' :PartnerDiscountInfo="PartnerDiscountInfo"
        @fromSelectMoney="fromSelectMoney" ref="selectMoney"></selectMoney>
    </div>
    <div>
      <!-- 输入卡密组件 -->
      <submission @formSubmission="formSubmission" :cardTypeObj="toSubmitCompontetObj" :isLogin="isLogin"
        ref="submission"></submission>
    </div>
    <div class="tips"> 注：提交前请先前往个人中心<span class="font16" @click="showZFBAlert">设置支付宝信息</span>（{{
      toSubmitCompontetObj.gc_remark ? toSubmitCompontetObj.gc_remark : '处理时间：9:00-24:00' }}）
    </div>
    <div class="SellSteps">
      <p>速卖步骤</p>
      <van-steps active="4" inactive-color="#f83130" active-color="#f83130">

        <van-step>提交卡号卡密</van-step>
        <van-step>等待卡密验证</van-step>
        <van-step>资金到账成功</van-step>
      </van-steps>
    </div>
    <div class="niuniuagreement">
      <van-radio-group v-model="checked">
        <van-radio v-if="checked == 1" :name="1" icon-size="14px" @click="clickAgreement" checked-color="#f83130">
          我已仔细阅读并理解

        </van-radio>
        <van-radio v-if="checked == 2" :name="2" icon-size="14px" @click="clickAgreement" checked-color="#a6a6a6">
          我已仔细阅读并理解
          <!-- <span @click="toniuniuAgreement" class="agreementColor"
            >&lt&lt牛牛收卡网转让协议&gt&gt</span
          > -->
        </van-radio>
      </van-radio-group>
      <span @click="toniuniuAgreement" class="agreementColor">&lt&lt牛牛收卡网转让协议&gt&gt</span>
    </div>

    <div class="submit">
      <button v-if="isLogin" @click="submint">确认提交卖卡</button>
      <button v-if="!isLogin" @click="toLogin">前往登录</button>
    </div>
    <transition name="fade">
      <van-overlay :show="isShowAlertZFBMengCen" @click="isShowAlertZFBMengCen = false" :lock-scroll="false">
        <ZFBAlert :userInfo="userInfoObj" @fromSonMessage="fromSonMessage"></ZFBAlert>
      </van-overlay>

    </transition>
    <BindPhone :showBindPhone="showBindPhone" @closeShowBindPhone="closeShowBindPhone" ref="bindPhone"
      :userInfo="userInfoObj"></BindPhone>
  </div>
</template>

<script>
import selectMoney from "./homeComponent/selectMoney";
import submission from "./homeComponent/submission";
import G_time from "../../tools/time";
import ZFBAlert from "./indexComponent/ZFBAlert.vue";
import BindPhone from "./personalCenterComponent/bindPhone.vue";
export default {
  data() {
    return {
      isLogin: false,
      showBindPhone: false,
      isShowAlertZFBMengCen: false,
      isOneOpen: true,
      toSubmitCompontetObj: {
        gc_submit_type: 0,
        gc_remark: "",
        gc_name: "",
      },
      logImg1: "",
      logImg2: "",
      cardType: 0,
      checked: 2,
      submitDisabled: false,
      cardTypeList: [
        {
          value: 3,
          text: "电商礼品卡",
        },
        {
          value: 4,
          text: "旅游卡",
        },
        {
          value: 1,
          text: "油卡",
        },
        {
          value: 2,
          text: "话费卡",
        },

        {
          value: 5,
          text: "商超百货卡",
        },
        {
          value: 6,
          text: "餐饮服务",
        },
      ],
      cardNameList: [],
      cardList: [],
      cardTypeObj: {
        gc_type: 3,
      },
      reqMoneyCardListObj: {
        gc_id: 2,
      },
      pager: {
        page: 1,
        limit: 100,
      },

      card_data: "",
      g_id: 0,
      PartnerDiscountInfo: {},
      userInfoObj: {
        u_ali_account: "",
        u_ali_realname: "",
      },
    };
  },

  components: {
    selectMoney,
    submission,
    ZFBAlert,
    BindPhone,
  },

  mounted() {
    this.getUserinfo();
    this.routeCardParams = this.$route.params;
    // 判断是否待卡种跳转
    if (this.routeCardParams.gc_type) {
      this.cardTypeObj.gc_type = this.routeCardParams.gc_type;

      console.log("带卡种跳转", this.routeCardParams);
    }
    // 判断是否带卡类跳转
    if (this.routeCardParams.gt_id) {
      this.cardTypeObj.gc_type = this.routeCardParams.gt_id;
    }
    this.getCardList();
  },

  methods: {
    toLogin() {
      this.$router.push({ path: "/Login" });
    },
    closeShowBindPhone() {
      this.showBindPhone = false;
    },
    getUserinfo() {
      this.$axios
        .get("/api/user/getInfo", {
          params: {
            isJumpLogin: true,
          },
        })
        .then((res) => {
          res.data.u_regist_time = G_time.ts2Date(res.data.u_regist_time);
          this.userInfoObj = res.data;
          console.log(res);
          if (res.code == "996" || res.code == "995") {
            this.isLogin = false;
            this.$dialog
              .confirm({
                title: "登录提醒",
                message: "需要登录后才可进行卡券转让哦！",
                confirmButtonText: "前往登录",
              })
              .then((res) => {
                this.$router.push({ path: "/Login" });
              });
          }
          if (res.code == "000") {
            this.isLogin = true;
            if (!res.data.u_phone) {
              this.showBindPhone = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fromSonMessage(isShow) {
      this.isShowAlertZFBMengCen = isShow;
    },
    showZFBAlert() {
      this.isShowAlertZFBMengCen = true;
    },
    formSubmission(str) {
      this.card_data = str;
    },
    fromSelectMoney(obj) {
      // console.log(obj);
      this.g_id = obj.g_id;
    },
    async submint() {
      if (this.checked == 2) {
        this.$toast({
          type: "fail",
          message: "请先同意转让协议",
        });
        return false;
      }
      let card_dataStr = this.$refs['submission'].pushData()
      let data = {
        gc_id: this.reqMoneyCardListObj.gc_id,
        g_id: this.g_id,
        card_data: card_dataStr,
      };
      let diyCost = await this.$refs.selectMoney.pushData();
      if (diyCost.submitType == 1) {
        if (!diyCost.custom_cost) {
          this.$toast({
            type: "fail",
            message: "请输入自定面值",
          });
          return false;
        }
        data.custom_cost = diyCost.custom_cost;
        // 自定义面值
      } else {
        if (!this.g_id) {
          this.$toast({
            type: "fail",
            message: "请选择面值",
          });
          return false;
        }
      }
      if (this.cardTypeObj.gc_type == 2) {
        let tips = this.getdialogTips(data, diyCost);
        this.$dialog
          .confirm({
            title: "温馨提示",
            message: tips,
          })
          .then(() => {
            this.addCardRecordReq(data);
          });
      } else {
        this.addCardRecordReq(data);
      }
    },
    getdialogTips(data, diyCost) {
      let cardList = data.card_data.split("\n");
      if (cardList[0] == "") {
        this.$toast({
          type: "fail",
          message: "请输入卡密",
        });
        throw "卡密为空";
      }
      let newCardList = Array.from(new Set(cardList));
      console.log(this.toSubmitCompontetObj, data, newCardList);
      let g_cost = "";
      for (let i = 0; i < this.cardList.length; i++) {
        if (this.cardList[i].g_id == this.g_id) {
          g_cost = this.cardList[i].g_cost;
        }
      }
      return `<div style='text-align: left;white-space: initial;'>
      <p style='margin:5px 0px;padding:0px'>提交卡种：${this.toSubmitCompontetObj.gc_name
        }</p>
      <p style='margin:5px 0px;padding:0px'>选择面值：${diyCost.gc_submit_type == 1 ? data.custom_cost : g_cost
        }元</p>
      <p style='margin:5px 0px;padding:0px'>提交卡密数量：${cardList.length
        }张</p>
      <p style='margin:5px 0px;padding:0px'>重复卡密数量：${cardList.length - newCardList.length
        }张</p>
      <p style='margin:5px 0px;padding:0px'>实际提交卡密数量：${newCardList.length
        }张</p>
      <p style="font-size:12px;color: red;margin:10px 0px ;padding:0px; white-space: pre-wrap;">  提交前请务必仔细核对卡密面值！如实际面值大于提交面值，则按照提交面值结算；如实际面值小于提交面值，则卡密会被使用且无法结算！因卡号/卡密或者面值填与错误导致的损失后果自负！(提交多张同面值的卡请按单张面值选面值，并非整批卡总面值，比如3张100面值请选100面值，不能选300面值，不同面值不能混合提交否则损失卡无法结算)，处理时间24小时，到账时间2小时 。</p> </div>`;
    },
    addCardRecordReq(data) {
      this.$axios
        .post("/api/shop/addSellRecord", data)
        .then((res) => {
          if (res.code == "000") {
            this.card_data = "";
            let tips = "提交成功";
            if (res.data.info.length > 0) {
              tips = "";
              // tips = JSON.stringify(res.data.info);
              for (let i = 0; i < res.data.info.length; i++) {
                tips += `<div style='width:100%;text-align: left'><strong>${i + 1
                  }：</strong>${res.data.info[i]}</div>`;
              }
              this.$dialog.alert({
                dangerouslyUseHTMLString: true,
                title: "失败订单",
                message: tips,
              });
            } else {
              this.$refs.submission.reset();
              this.$toast({
                type: "success",
                message: tips,
              });
            }
          } else {
            if (res.code != "996") {
              this.$toast({
                type: "fail",
                message: res.msg,
              });
            }
          }
        })
        .catch((err) => {
          // tis.submitDisabled = true;
          this.$toast({
            type: "fail",
            message: "提交失败",
          });
          console.log(err);
        });
    },
    // 切换卡类
    changeCardType(val) {
      this.cardTypeObj.gc_type = val;
      this.cardNameList = [];
      // console.log(this.cardTypeObj);
      this.getCardList();
    },
    // 切换卡种
    changeCard(value) {
      this.reqMoneyCardListObj.gc_id = value.gc_id;
      // console.log("切换卡种", value);
      this.toSubmitCompontetObj = value;
      // 清除卡密文本框内容
      this.card_data = "";
      this.$refs.submission.reset();
      let obj = this.cardNameList.find((i) => {
        //listData就是上面的数据源
        return i.gc_id === this.reqMoneyCardListObj.gc_id; //筛选出匹配数据
      });
      this.logImg2 = obj.gc_cover;

      this.g_id = "";
      this.cardList = [];
      // this.getPartnerDiscountInfo();
      this.getMoneyCardList();
    },
    // 获取卡种
    getCardList() {
      this.$axios
        .get("/api/shop/getGoodsClassList", {
          params: { ...this.cardTypeObj, ...this.pager },
        })
        .then((res) => {
          this.cardList = [];
          if (res.code == "000") {
            this.cardNameList = res.data;
            if (this.cardNameList.length < 1) {
              this.reqMoneyCardListObj.gc_id = "";
              this.logImg2 = "";
              this.toSubmitCompontetObj = {};
            }
            this.reqMoneyCardListObj.gc_id = this.cardNameList[0].gc_id;
            this.logImg2 = this.cardNameList[0].gc_cover;
            this.toSubmitCompontetObj = this.cardNameList[0];
            if (this.$route.params.gc_type && this.isOneOpen) {
              this.reqMoneyCardListObj.gc_id = this.$route.params.gc_id;
              this.logImg2 = this.$route.params.gc_cover;
              this.toSubmitCompontetObj = this.$route.params;
              this.isOneOpen = false;
            }
            // this.getPartnerDiscountInfo();
            this.getMoneyCardList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取面值
    getMoneyCardList() {
      this.g_id = "";
      this.$axios
        .get("/api/shop/getGoodsList", {
          params: {
            ...this.reqMoneyCardListObj,
            ...this.pager,
            partner_id: window.localStorage.getItem("partner_id")
              ? window.localStorage.getItem("partner_id")
              : 0,
          },
        })
        .then((res) => {
          if (res.code == "000") {
            this.cardList = res.data;
            // 清空自定义面值
            this.$refs.selectMoney.clearCustomCost();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    selectCardType(Type) {
      this.cardType = Type;
    },

    clickAgreement() {
      console.log(this.checked);
      if (this.checked == 1) {
        this.checked = 2;
      } else {
        this.checked = 1;
      }
    },
    toniuniuAgreement() {
      this.$router.push({
        path: "/niuniuAgreement",
      });
    },
    async getPartnerDiscountInfo(gc_id) {
      let res = await this.$axios.get("/api/shop/getPartnerDiscountInfo", {
        params: {
          gc_id: this.reqMoneyCardListObj.gc_id,
        },
      });
      this.PartnerDiscountInfo = res.data;
      console.log(res);
    },
  },
};
</script>
<style lang='scss'>
.home {
  font-size: 15px;
  // margin-left: 10px;
  margin-top: 52px;

  .cardType {
    display: flex;
    justify-content: space-between;
    width: 100%;
    // height: 65px;
    margin-left: 10px;
    // margin-right: 10px;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;

    .selectCardType {
      margin: 0 0 0 10px;
      border: 1px solid #f83130;
      border-radius: 3px;
      height: 30px;
      width: 100px;
      background: #fff;
    }

    .typeLogo {
      margin-right: 15px;
      width: 40px;
      height: 40px;
    }

    .type {
      display: inline-block;
      width: 37px;
      margin-right: 5px;
      color: grey;
    }

    .cardTable {
      // width: 70px;
      padding: 0 5px 0 5px;
      display: inline-block;
      // margin-left: 5px;
      // padding-left: 5px;
      // padding-right: 5px;
      text-align: center;
      height: 30px;
      line-height: 30px;
      box-sizing: border-box;
      margin-bottom: 5px;
    }

    .selectTable {
      border: 1px solid #f83130;
      border-radius: 15px;
      color: #f83130;
    }
  }

  .selectMoney {
    // border: 1px solid #000;
    margin: 0px 0px 10px 0;
  }

  .SellSteps {
    padding: 20px;
    margin-top: 10px;
    background: #f7f7f7;

    p {
      padding: 0px;
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .niuniuagreement {
    font-size: 12px;
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 600;
    display: flex;

    .agreementColor {
      color: #f83130;
    }
  }

  .submit {
    margin-top: 20px;
    width: 100%;
    text-align: center;

    button {
      width: 300px;
      height: 40px;
      border-radius: 5px;
      border: none;
      background: #f83130;
      color: #fff;
    }
  }

  .mg-l20 {
    margin-left: 10px;
  }

  .tips {
    color: #f83130;
    font-size: 12px;
    margin: 0 10px;
  }

  .font16 {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
  }
}
</style>