<!-- 选择面值 -->
<template>
  <div class="selectMoneyComponent">
    <p class="tableText">选择面值</p>
    <div class="cardList">

      <p class="cardMoney" v-for="(item, index) in cardList" :key="index" @click="selectMoney(item)"
        :style="{ background: item.g_id == selectMoneyId ? '#ffe5e5' : '#f7f7f7', border: item.g_id == selectMoneyId ? '1px solid #f83130' : 'none' }">
        <span>{{ item.g_cost }}元</span>
        <span style="color:#f83130">{{ item.g_discount }} 折</span>
        <span style="color:#f83130">到账{{ item.g_account_price }}元</span>
      </p>
      <!-- <p
        class="cardMoney"
        v-for="(item,index) in cardList"
        :key="index"
        @click="selectMoney(item)"
        :style="{background:item.g_id==selectMoneyId?'#ffe5e5':'#f7f7f7',border:item.g_id==selectMoneyId?'1px solid #f83130':'none'}"
      >
        <span>{{item.g_cost}}元</span>
        <span style="color:#f83130">{{item.g_cost| discount(PartnerDiscountInfo)}} 折</span>
        <span style="color:#f83130">到账{{item.g_cost | accountPrice(PartnerDiscountInfo)}}元</span>
      </p> -->
    </div>
    <div class="customValues" v-if="cardTypeObj.show_custom_cost == 1">
      <p style="margin-left: 10px;
    color: #f40;">*以上面值无法满足您的需要？您可以输入自定义面值</p>
      <div class="totalMoney">
        <van-field v-model="form.custom_cost" name="请输入自定义面值" label="自定义面值：" placeholder="请输入单张卡面值" type="digit"
          ref="totalMoney" size="large" :error-message="g_costErroer" @input="changeGcost" />
      </div>
      <div class="predictMoney">
        <!-- <p>折扣：小于{{PartnerDiscountInfo.division_cost}}按{{PartnerDiscountInfo.less_cost_discount*10000/1000}}折，大于{{PartnerDiscountInfo.division_cost}}按{{PartnerDiscountInfo.high_cost_discount*10000/1000}}折</p> -->
        <span>预计到账金额：</span>
        <!-- <span>{{ g_account_price }}元</span> -->
        <span>{{ req_account_price }}元</span>
      </div>
    </div>
  </div>
</template>

<script>
import debounceAndThrottle from "../../../tools/debounceAndThrottle";
export default {
  props: ["cardList", "cardTypeObj", "PartnerDiscountInfo"],
  data() {
    return {
      selectMoneyId: -1,
      form: {
        custom_cost: "",
      },
      g_costErroer: "",
      submitType: 0,
      req_account_price: "",
    };
  },

  components: {},
  computed: {
    g_account_price: function () {
      let price = 0;
      if (
        this.form.custom_cost * 10000 <
        this.PartnerDiscountInfo.division_cost * 10000
      ) {
        price =
          (this.form.custom_cost *
            (Number(this.PartnerDiscountInfo.less_cost_discount) * 1000)) /
          1000;
      } else {
        price =
          (this.form.custom_cost *
            (Number(this.PartnerDiscountInfo.high_cost_discount) * 1000)) /
          1000;
      }
      return price;
    },
  },
  mounted() { },
  filters: {
    discount(gc_cost, PartnerDiscountInfo) {
      return gc_cost * 1000 < PartnerDiscountInfo.division_cost * 1000
        ? (PartnerDiscountInfo.less_cost_discount * 10000) / 1000
        : (PartnerDiscountInfo.high_cost_discount * 10000) / 1000;
    },
    accountPrice(gc_cost, PartnerDiscountInfo) {
      return gc_cost * 1000 < PartnerDiscountInfo.division_cost * 1000
        ? (((gc_cost * 1000) / 1000) *
          (PartnerDiscountInfo.less_cost_discount * 10000)) /
        10000
        : (((gc_cost * 1000) / 1000) *
          (PartnerDiscountInfo.high_cost_discount * 10000)) /
        10000;
    },
  },

  methods: {
    clearCustomCost() {
      this.form.custom_cost = "";
      this.req_account_price = "";
    },
    changeGcost(value) {
      if (value != "") {
        this.selectMoneyId = 0;
        this.submitType = 1;
        this.$emit("fromSelectMoney", { g_id: 0 });
        debounceAndThrottle.debounce(() => {
          this.$axios
            .post("/api/shop/getCustomCostDiscount", {
              gc_id: this.cardTypeObj.gc_id,
              g_cost: value,
            })
            .then((res) => {
              this.req_account_price = res.data.account_price;
            });
        }, 500);
      } else {
        this.req_account_price = "";
      }
    },

    selectMoney(obj) {
      this.selectMoneyId = obj.g_id;
      this.submitType = 0;
      this.form.custom_cost = "";
      this.req_account_price = "";
      this.$emit("fromSelectMoney", obj);
    },
    async pushData() {
      return {
        submitType: this.submitType,
        custom_cost: this.form.custom_cost,
      };
    },
  },
};
</script>
<style lang='scss'>
.selectMoneyComponent {
  .tableText {
    color: #fff;
    margin-top: 10px;
    padding: 5px;
    background: #cf7979eb;
    width: 84px;
    /* font-size: 19px; */
    border-radius: 0px 5px 5px 0px;
  }

  .cardList {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin: 15px 0 0 5px;
    padding: 0px;
  }

  .cardMoney {
    display: inline-block;
    width: 107px;
    height: 70px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex: 0 0 30%;
    margin-left: 5px;
    margin-right: 5px;
    box-sizing: border-box;
  }

  .predictMoney {
    font-size: 15px;
    margin: 10px 10px 20px 10px;
    color: #f83130;
  }
}
</style>