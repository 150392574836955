<!-- 绑定手机号 -->
<template>
    <div class="bindPhone">
        <van-dialog v-model="showBindPhone" @confirm="bindPhone" @cancel="closeBindPhone"
            :before-close="beforeCloseBindPhone" title="请绑定手机号" show-cancel-button>
            <van-field type="number" placeholder="请输入手机号" v-model="form.u_phone" :error-message="phoneErrorMessage" />
            <div class="codeFather">
                <van-field type="number" placeholder="请输入验证码" v-model="form.code" :error-message="codeErrorMessage" />
                <span class="getCode" @click="getCode" :style="{ color: getCodeTitle == 1 ? '#f83130' : '#000' }">{{
                    getCodeTitle ==
                    1 ?
                    "获取验证码" : countDown + "秒" }}</span>
            </div>
        </van-dialog>
    </div>
</template>

<script>
export default {
    props: {
        userInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        showBindPhone: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // showBindPhone: true,
            getCodeTitle: 1,
            countDown: 60,
            form: {
                u_phone: '',
                code: ''
            },
            phoneErrorMessage: '',
            codeErrorMessage: ''
        };
    },
    watch: {
        showBindPhone: {
            handler: function (showBindPhone) {
                console.log(showBindPhone)
            }
        }
    },
    components: {},

    mounted() { },

    methods: {
        async bindPhone() {
            let form = await this.pushData()
            let res = await this.$axios.post('/api/user/bindPhone', form)

            if (res.code == '000') {
                this.$toast({
                    type: "success",
                    message: "绑定成功",
                });
                this.restForm()
                this.getUserinfo()
                this.$emit('closeShowBindPhone')
                this.$emit('getUserinfo')
            } else {
                this.$toast({
                    type: "fail",
                    message: res.msg,
                });
            }
        },
        beforeCloseBindPhone(action, done) {
            done(false)
        },
        closeBindPhone() {
            this.restForm()
            this.$emit('closeShowBindPhone')
        },
        async getCode() {
            if (!this.form.u_phone) {
                this.phoneErrorMessage = '手机号不能为空'
                return false
            }
            this.phoneErrorMessage = ''
            let res = await this.$axios.post('/api/user/verify', { u_phone: this.form.u_phone, type: 'bind' })
            if (res.code == '000') {
                this.getCodeTitle = 0
                this.startCountDown()
                this.$toast({
                    type: "success",
                    message: "验证码已发送",
                });
            } else {
                this.$toast({
                    type: "fail",
                    message: res.msg,
                });
            }


        },
        async pushData() {
            if (!this.form.u_phone) {
                this.phoneErrorMessage = '手机号不能为空'
                throw '手机号为空'
            }
            if (!this.form.code) {
                this.codeErrorMessage = '验证码不能为空'
                throw '验证码为空'
            }
            return this.form
        },

        restForm() {
            this.form = {
                u_phone: '',
                code: ''
            }
        },

        startCountDown() {
            let CountDownSetInterval = setInterval(() => {
                if (this.countDown > 0) {
                    this.countDown--;
                } else {
                    this.getCodeTitle = 1;
                    this.countDown = 60;
                    clearInterval(CountDownSetInterval);
                }
            }, 1000);
        },

    }
}

</script>
<style scoped>
.bindPhone {
    margin: 10px 5px 10px 5px;
}

p {
    padding: 0px;
    margin: 50px 10px 10px 10px;
    border-bottom: 1px solid #a7a5a5;
    padding: 5px;
    display: flex;
    align-items: center;
    position: relative;
}

input {
    border: none;
    border-bottom: 1px solid #868585;
    border-radius: 5px;
    outline: none;
    background: #fff;
    margin: 5px 0 10px 5px;
}

.codeFather {
    display: flex;
    align-items: center;
}

.getCode {
    color: #f83130;
    margin-left: 10px;
    display: inline-block;
    width: 150px;
    /* position: absolute;
    right: 4px; */
}
</style>