<!-- 选择提交方式 -->
<template>
  <div class="submission">

    <p class="tableText">卡号卡密</p>
    <!-- <div style="color: red;" v-if="!isLogin">需要登录后才能使用哦！</div> -->
    <div class="submitType">

      <div class="textareaText">
        <van-field class="textareaBatch" v-model="BatchSubmission" @blur="changeCardDtr" :rows="isLogin ? 10 : 5"
          :border="true" autosiz='{ maxHeigh:300px}' type="textarea" :disabled="!isLogin"
          :placeholder="isLogin ? cardTypeObj.gc_submit_demo : '请先登录后再提交卡券！'" :error-message="errorMessage" />
        <!-- <p class="demo"><span @click="showDemo">
            {{ cardTypeObj.gc_name }}示例 </span></p> -->
        <p class="demo">
          <span @click="reset" style="color: #f40">
            清空内容 </span>
          <button v-if="isLogin" class="trimCardButton" @click="clippingCardData">
            一键整理 </button>
          <button v-else class="trimCardButton" @click="toLogin">
            立即登录 </button>
        </p>

      </div>

    </div>

    <van-overlay :show="showSubmitDemo">
      <div class="wrapper">
        <div class="block">
          <header> {{ cardTypeObj.gc_name }}提交示例</header>
          <main>

            <pre style="white-space:pre-wrap">{{ cardTypeObj.gc_submit_demo }}</pre>
          </main>
          <footer>
            <button @click="closeDemo">我知道了</button>
          </footer>
        </div>
      </div>
    </van-overlay>

  </div>
</template>

<script>
export default {
  props: {
    isLogin: {
      type: Boolean,
      default: false
    },
    cardTypeObj: {
      default: {
        gc_submit_type: 0,
        gc_remark: "",
        gc_name: "",
      },
      type: [Object],
    },
  },
  data() {
    return {
      showSubmitDemo: false,
      submitType: 1,
      BatchSubmission: "",
      errorMessage: ''
    };
  },

  components: {},

  mounted() { },

  methods: {
    toLogin() {
      this.$router.push({ path: "/Login" });
    },
    closeDemo() {
      this.showSubmitDemo = false;
    },
    showDemo() {
      this.showSubmitDemo = true;
    },
    reset() {
      this.errorMessage = ''
      this.BatchSubmission = "";
    },
    changeCardDtr() {

      this.$emit("formSubmission", this.BatchSubmission);
    },
    pushData() {
      let cloneStr = this.BatchSubmission
      try {
        this.clippingCardData()
      } catch (error) {
        this.BatchSubmission = cloneStr
      }
      return this.BatchSubmission
    },
    clippingCardData() {
      let rulData = JSON.parse(this.cardTypeObj.gc_verify_rule),
        cardNumLength = Number(rulData.card_num.min_length),
        cardDataLength = Number(rulData.card_data.min_length),
        arr = []
      if (!cardNumLength && !cardDataLength) {
        return false
      }

      // 需要卡号
      if (cardNumLength) {
        arr = this.splitStringByLength(this.BatchSubmission.replace(/[^a-zA-Z0-9]/g, ""), cardNumLength + cardDataLength, cardNumLength, cardDataLength)
      } else {
        arr = this.splitStringByLength(this.BatchSubmission.replace(/[^a-zA-Z0-9]/g, ""), cardDataLength)
      }
      this.BatchSubmission = ''
      for (let i = 0; i < arr.length; i++) {
        this.BatchSubmission += arr[i] + '\n'
      }
      this.BatchSubmission = this.BatchSubmission.replace(/\n$/, '')
    },

    splitStringByLength(str, len, cardNumLength, cardDataLength) {
      if (str.length <= len) {
        if (cardNumLength) {
          // 需要卡号
          if (/[, ]+/.test(str) || str.length < cardNumLength) {
            // 包含空格或逗号,或长度不够
            return [str];
          } else {
            return [`${str.substring(0, cardNumLength)},${str.substring(cardNumLength, str.length)}`]
          }
        } else {
          return [str];
        }
      }
      else {
        let temp = str.substring(0, len),
          firstSubLen = len
        if (cardNumLength) {
          temp = str.substring(0, len + 1);
          if (!/[, ]+/.test(temp)) {
            temp = `${str.substring(0, cardNumLength)},${str.substring(cardNumLength, cardNumLength + cardDataLength)}`
          } else {
            firstSubLen = len + 1
          }
        }

        return [temp].concat(this.splitStringByLength(str.substring(firstSubLen), len, cardNumLength, cardDataLength));

      }
    },
    trimCardString() {
      if (!this.BatchSubmission) {
        this.$toast({
          type: "fail",
          message: "提交内容不可为空",
        });
        this.errorMessage = '提交内容不可为空'
        return false
      }
      let cardList = this.BatchSubmission.split('\n'),
        str = '',
        errList = [],
        rulData = JSON.parse(this.cardTypeObj.gc_verify_rule)
      cardList.forEach((item, index) => {
        let errData = { index, tips: [] },
          cardNumMinLength = Number(rulData.card_num.min_length),
          cardNumMaxLength = Number(rulData.card_num.max_length),
          cardDataMinLength = Number(rulData.card_data.min_length),
          cardDataMaxLength = Number(rulData.card_data.max_length)

        // 需要卡号
        if (cardNumMinLength) {
          // 计算连续空格有几处
          let spacesNumber = item.match(/ +/g)?.length || 0,
            // 计算连续逗号有几处
            commaNumber = item.match(/,/g)?.length || 0
          console.log(spacesNumber, commaNumber)
          if (commaNumber > 1) {
            errData.commaNumber = commaNumber
            errData.tips.push('存在多处逗号')
          }
          if (spacesNumber < 1 && commaNumber < 1) {
            errData.tips.push('未使用逗号或空格分割卡号卡密')
          } {
            if (commaNumber == 1) {
              console.log('asdasd----')
              // 有逗号分割时，删除所有空格
              cardList[index] = cardList[index].replace(/\s/g, '')
            } else {
              // 无逗号分割时判断有多处连续空格
              if (spacesNumber > 1) {
                errData.spacesNumber = spacesNumber
                errData.tips.push('存在多处空格')
              }
            }
            console.log(cardList[index], '====111')
            // 去除除_逗号与空格以外的字符
            cardList[index] = cardList[index].replace(/[^?,-\w\s]/g, '')
            // 去除首尾空格
            cardList[index] = cardList[index].replace(/^\s+|\s+$/g, '')
            console.log(cardList[index], '====')
            // 根具逗号或空格分割卡号卡密
            let arr = cardList[index].split(/[,， ]\s*/)
            let cardNumLength = arr[0]?.length || 0,
              cardDataLength = arr[1]?.length || 0
            if ((cardNumLength < cardNumMinLength || cardNumLength > cardNumMaxLength) || cardDataLength < cardDataMinLength || cardDataLength > cardDataMaxLength) {
              errData.tips.push('卡号或卡密长度不符合要求')
            }
          }
        } else {
          // 不需要卡号
          // 去除所有特殊字符
          cardList[index] = item.replace(/[^\w]/g, '')
          if (cardList[index].length < cardDataMinLength || cardList[index].length > cardDataMaxLength) {
            errData.tips.push(`卡密长度不符合要求`)
          }
        }
        // 字符串连续空格只保留一个
        cardList[index] = cardList[index].replace(/\s+/g, ' ')
        str = str + cardList[index] + '\n'
        if (errData.tips.length > 0) {
          errList.push(errData)
        }
      });
      // 删除字符串最后一个换行符
      this.BatchSubmission = str.replace(/\n$/, '')
      this.errorMessage = ''
      for (let i = 0; i < errList.length; i++) {
        this.errorMessage += `第${errList[i].index + 1}，`
        this.errorMessage += '行格式错误。请检查！'
      }
      // console.log(errList)
      // if (errList.length > 0) {
      //   this.$toast({
      //     type: "fail",
      //     message: "提交内容格式错误",
      //   });
      // } else {
      //   return true
      // }
    }
  },
};
</script>
<style lang='scss'>
.submission {
  .tableText {
    color: #fff;
    margin: 0px;
    padding: 5px;
    background: #cf7979eb;
    width: 84px;
    /* font-size: 19px; */
    border-radius: 0px 5px 5px 0px;
  }

  .submitType {
    margin: 15px 0 0 0;
    padding: 0px;
  }

  .discount {
    margin: 10px 0px 0px 0px;
    padding: 0px;

    .discountNumber {
      color: #f83130;
    }
  }

  .textareaText {
    border: 1px solid #e47642;
    margin: 10px 10px 10px 10px;
    background: #f7f7f7;
    border-radius: 3px;

    // padding: 10px 10px 0px 10px;
    .textareaBatch {
      // background: #f7f7f7;
      // padding: 0px;
      // margin: 5px;
    }
  }

  .demo {
    padding: 0px;
    margin: 0 0px 0px 0px;
    width: 100%;
    // text-align: right;
    display: flex;
    justify-content: space-between;

    span {
      margin: 4px 10px 2px 5px;
      color: #f49a06;
      text-decoration: underline;
    }

    .trimCardButton {
      color: #fff;
      border-bottom: 0px;
      border-right: 0px;
      border-left: 1px solid #f80000;
      border-top: 1px solid #f60000;
      background: #c30000;
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
      padding: 4px;
      margin: 0 0px 0px 0;
    }

  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .van-overlay {
    z-index: 9;
  }

  .block {
    width: 290px;
    height: 300px;
    border-radius: 10px;
    background-color: #fff;
    position: relative;

    header {
      width: 100%;
      text-align: center;
      padding: 5px 0 5px 0;
      font-size: 15px;
      font-weight: 600;
      border-bottom: 1px solid rgb(95, 94, 94);
    }

    main {
      margin: 5px;
      font-size: 12px;
      color: #f83130;
      background: #fff;

      .tipsText {
        color: #f83130;
        font-size: 15px;
      }
    }

    footer {
      position: absolute;
      bottom: 10px;
      width: 100%;
      text-align: center;

      button {
        border: none;
        height: 30px;
        width: 80px;
        border-radius: 15px;
        color: #fff;
        background: #f83130;
      }
    }
  }

  // .submitDemo {
  //   position: fixed;
  //   top: 0px;
  //   left: 0px;
  //   background: rgba(0, 0, 0, 0.438);
  //   width: 100vw;
  //   height: 100vh;
  //   z-index: 999;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   .demoAlert {
  //     width: 200px;
  //     height: 300px;
  //     background: #fff;
  //   }
  // }

}
</style>